import { GlobalError } from "@dnb/eufemia/components";
import React from "react";

interface Props {
    path: any;
};

const NotFoundComponent: React.ElementType<Props> = ({ }) => {
    return (
        <GlobalError style={{ position:'sticky' }} status="404" />
    )
}

export default NotFoundComponent;